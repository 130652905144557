import { useState } from 'react';
import * as api from '../../api';
import { productAPI } from '../../config';
import ErrorBox from '../../generic/ErrorBox';
import SpinnerContainer from '../../generic/SpinnerContainer';
import { PaginationType } from '../../model/types';
import ProductList from '../../search/ProductList';
import useBooleanState from '../../utils/useBooleanState';
import { useOnMount } from '../../utils/useComponentDidMount';

interface Props {
    productId: string;
}

const PAGE_SIZE = 4;

export default function SimilarProducts({ productId }: Props) {
    const [products, setProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(0);
    const [message, setMessage] = useState(null);
    const [isLoading, setLoading] = useBooleanState(true);
    const [error, setError] = useState(null);

    useOnMount(() => {
        api.get(productAPI.similarProductsUrl(productId))
            .then(({ message, products }) => {
                setMessage(message);
                setProducts(products);
            })
            .catch(setError)
            .finally(setLoading.toFalse);
    });

    const currentProducts = products.slice(currentPage * PAGE_SIZE, (currentPage + 1) * PAGE_SIZE);
    const pagination: PaginationType = {
        currentPage,
        pageSize: PAGE_SIZE,
        sort: '',
        totalPages: Math.ceil(products.length / PAGE_SIZE),
        totalResults: products.length,
    };

    return (
        <SpinnerContainer isSpinning={isLoading}>
            <div className="similar-products">
                <ErrorBox errors={error} />
                <p className="message">{message}</p>
                <ProductList
                    products={currentProducts}
                    numberOfProductsWidth={PAGE_SIZE}
                    pagination={pagination}
                    onGotoPage={setCurrentPage}
                    scrollToTop={false}
                    tracking_name="Produkter som ligner"
                    tracking_id={productId}
                />
            </div>
        </SpinnerContainer>
    );
}
