import Modal from './Modal';
import OkCancelButtons from './OkCancelButtons';

export interface Props {
    heading?: string;
    mainText: string;
    confirmButtonText?: string;
    isOpen: boolean;
    onClose(): void;
    onConfirm(): void;
}

export default function ConfirmModal({ isOpen, heading, mainText, onClose, onConfirm, confirmButtonText = 'OK' }: Props) {
    return (
        <Modal onClose={onClose} isOpen={isOpen} size="small" padding>
            <h3>{heading}</h3>
            <p>{mainText}</p>
            <OkCancelButtons onCancel={onClose} onOK={onConfirm} okButtonText={confirmButtonText} centered />
        </Modal>
    );
}
