import { PointerEvent, useContext, useMemo } from 'react';
import { UserContext } from '../../login/userContext';
import { Product } from '../../model/types';
import useBooleanState from '../../utils/useBooleanState';

export interface Props {
    product: Product;
    zoomImage?: boolean;
    showPressImageLink?: boolean;
}

export default function ProductDetailsImage({ product, zoomImage = false, showPressImageLink = false }: Props) {
    const [showZoom, setShowZoom] = useBooleanState();

    const zoomImageUrl = useMemo(() => getUrlForImageType(product, 'superZoom') ?? '', [product]);
    const largeImageUrl = useMemo(() => getUrlForImageType(product, 'zoom') ?? '', [product]);
    const defaultImageUrl = useMemo(() => getUrlForImageType(product, 'product') ?? '', [product]);
    const altText = `Bilde av produktet ${product.name}`;
    const canZoom = zoomImage || undefined;

    return (
        <div className="product__image-container zoomable">
            <picture>
                <source media="(max-width: 1440px)" srcSet={defaultImageUrl} />
                <source media="(min-width: 1440px)" srcSet={largeImageUrl} />
                <img
                    className={`${product.expired ? 'expired' : ''}`}
                    src={'https://bilder.vinmonopolet.no/bottle.png'}
                    alt={altText}
                    onPointerEnter={canZoom && setShowZoom.toTrue}
                    onPointerLeave={canZoom && setShowZoom.toFalse}
                    onPointerMove={canZoom && reposition}
                />
            </picture>
            {showZoom && <img className="zoom" src={zoomImageUrl} alt={altText} loading="lazy" />}

            <Expired product={product} />
            <PressImageLink showPressImageLink={showPressImageLink} product={product} />
        </div>
    );
}

function Expired({ product }: { product: Product }) {
    return product.expired ? <div className="expired-img">Utgått</div> : null;
}

function PressImageLink({ showPressImageLink, product }: { showPressImageLink: boolean; product: Product }) {
    const { user } = useContext(UserContext);

    if (!showPressImageLink) return null;
    if (!user?.pressUser) return null;

    return (
        <div>
            <a className="no-print" href={`https://bilder.vinmonopolet.no/cache/5000x5000-0/${product.code}-1.jpg`} target="_blank" rel="noreferrer">
                Last ned bilde i høy oppløsning
            </a>
        </div>
    );
}

function reposition(e: PointerEvent<HTMLImageElement>) {
    const img = e.currentTarget;
    const lensImg = img.parentElement?.nextElementSibling as HTMLImageElement | undefined;
    if (!lensImg) return;
    const { x, y, width, height } = img.getBoundingClientRect();

    const posX = e.clientX - x;
    const posY = e.clientY - y;

    const halfWidth = width / 2;
    const halfHeight = height / 2;

    const tx = halfWidth - (posX / img.offsetWidth) * lensImg.naturalWidth;
    const ty = halfHeight - (posY / img.offsetHeight) * lensImg.naturalHeight;

    lensImg.style.objectPosition = `${tx}px ${ty}px`;
}

function getUrlForImageType(product: Product, type: string) {
    return product.images?.find((image) => image.format === type)?.url ?? '';
}
