import cn from 'classnames';
import { PropsWithChildren } from 'react';
interface Props {
    selected: boolean;
    title: string;
    className?: string;
}

export default function ProductTab({ selected = false, title, className, children }: PropsWithChildren<Props>) {
    return (
        <div className={cn('accordion__panel', { selected }, className)}>
            <h5 className="accordion__header-print">{title}</h5>
            {selected && children}
        </div>
    );
}
