interface Props {
    product: ProductArea
}

export interface ProductArea {
    main_country?: { name: string; url: string; };
    district?: { name: string; url: string; };
    sub_District?: { name: string; url: string; };
}

export default function ProductOriginLinks({product}: Props){
    return (
        <>
            {!!product.main_country && <a href={product.main_country.url}>{product.main_country.name}</a>}
            {!!product.district && <>{', '} <a href={product.district.url}>{product.district.name}</a></>}
            {!!product.sub_District && <>{', '} <a href={product.sub_District.url}>{product.sub_District.name}</a></>
            }
        </>
    );
}