import { DetailedProduct } from './ProductDetailsPage';

export const getProductStructuredData = (product: DetailedProduct) => {
    try {
        return JSON.stringify({
            '@context': 'https://schema.org',
            '@type': 'Product',
            name: product.name,
            description: product.main_category?.name + (product.taste ? ', ' + product.taste : ''),
            offers: {
                '@type': 'Offer',
                price: product.price.value,
                priceCurrency: 'NOK',
            },
            image: product.images?.find((image) => image.format === 'product')?.url,
            brand: product.main_producer?.name
                ? {
                      '@type': 'Brand',
                      name: product.main_producer.name,
                  }
                : null,
            url: product.url,
            keywords: product.stickers?.map((sticker) => sticker.title) ?? [],
            size: product.volume?.formattedValue ?? null,
            countryOfOrigin: product.main_country?.name ?? null,
            color: product.color,
            productionDate: product.year != '0000' ? product.year : '',
        });
    } catch (e) {
        console.error(e);
        return null;
    }
};

function addToDescription(desc: string, additional: string) {
    if (desc.length + additional.length <= 130) {
        return desc + additional;
    }
    return desc;
}

export const createMetaDescription = (product: DetailedProduct) => {
    let description = product.main_sub_category?.name ?? product.main_category?.name + '';
    description = addToDescription(description, product.taste ? '. ' + product.taste : '');
    description = addToDescription(description, product.smell ? ' ' + product.smell : '');
    description = addToDescription(description, ' ' + product.price.formattedValue);
    description = addToDescription(description, product.volume.formattedValue ? ', ' + product.volume.formattedValue : '');
    return description;
};

export function addMetaDescription(product: DetailedProduct) {
    const metaDescription = document.createElement('meta');
    metaDescription.name = 'description';
    metaDescription.content = createMetaDescription(product);
    document.getElementsByTagName('head')[0].appendChild(metaDescription);
}
