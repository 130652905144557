import { getCategories, getPackagingString, isGiftCategory } from '../../model/Product';
import { Product } from '../../model/types';
import ProductOriginLinks, { ProductArea } from './ProductOriginLinks';

interface Props {
    product: DetailsProductTab;
}

export interface DetailsProductTab extends Product, ProductArea {
    smell?: string;
    taste?: string;
    color?: string;
    method?: string;
    allergens?: string;
    environmentLabel?: string;
    cork?: string;
    product_selection?: string;
    storeCategory?: string;
    wholeSaler?: string;
    environmentalPackaging?: string;
    packageType?: string;
    distributor?: string;
    main_producer?: { url?: string; name?: string };
    year?: string;
}

export default function ProductTabDetails({ product }: Props) {
    const isGiftPackaging = isGiftCategory(product);
    return (
        <ul className="product__tab-list">
            {renderItem('Varetype', getCategories(product))}
            {renderItem('Varenummer', product.code)}
            {renderItem('Lukt', product.smell)}
            {renderItem('Smak', product.taste)}
            {renderItem('Farge', product.color)}
            {!!product.main_country && (
                <li key="Land, distrikt">
                    <span>Land, distrikt</span>
                    <span>
                        <ProductOriginLinks product={product} />
                    </span>
                </li>
            )}
            {!!product.main_producer && (
                <li key="Produsent">
                    <span>Produsent</span>
                    <span>
                        <a href={product.main_producer.url} rel="nofollow">
                            {product.main_producer.name}
                        </a>
                    </span>
                </li>
            )}
            {!!product.year && (
                <li key="year">
                    <span>
                        Årgang
                        <span aria-describedby="product__year-footnote" className="product__year-footnote">
                            *
                        </span>
                    </span>
                    <span>
                        {product.year}
                        <br />
                        <em id="product__year-footnote">* Vi tar forbehold om at varen som leveres kan ha en annen årgang/etikett enn oppgitt</em>
                    </span>
                </li>
            )}
            {renderItem('Metode', product.method)}
            {renderItem('Inneholder', product.allergens)}
            {renderItem('Miljøsertifisert produksjon', product.environmentLabel)}
            {renderItem('Emballasjetype', getPackagingString(product))}
            {renderItem('Korktype', product.cork)}
            {!isGiftPackaging && renderItem('Utvalg', product.product_selection)}
            {!isGiftPackaging && renderItem('Kategori', product.storeCategory)}
            {!isGiftPackaging && renderItem('Grossist', product.wholeSaler)}
            {!isGiftPackaging && renderItem('Transportør', product.distributor)}
        </ul>
    );
}

const renderItem = (name: string, value?: string) => {
    if (!value) return null;

    return (
        <li key={name}>
            <span>{name}</span>
            <span>{value}</span>
        </li>
    );
};
