import cn from 'classnames';
import { useEffect, useState } from 'react';
import { useMediaQuery } from 'usehooks-ts';
import Expandable from '../../Expandable/Expandable';
import { UserData } from '../../api/product';
import TabButton from '../../generic/TabButton';
import { WrapTrackingContext } from '../../generic/TrackingContext';
import RatingAndComment from '../RatingAndComment';
import { DetailedProduct } from './ProductDetailsPage';
import ProductTab from './ProductTab';
import ProductTabDetails from './ProductTabDetails';
import SimilarProducts from './SimilarProducts';

interface Props {
    product: DetailedProduct;
    userData: UserData | undefined;
    onUserDataChange(userData: UserData): void;
}

export default function ProductDetailsTabs({ product, userData, onUserDataChange }: Props) {
    const [selectedTab, setSelectedTab] = useState('1');
    const matches = useMediaQuery('(max-width: 568px)');

    useEffect(() => {
        if (!matches && selectedTab === '') {
            setSelectedTab('1');
        }
    }, [matches, selectedTab]);

    const selectExpandable = (tabNumber: string) => {
        const newSelectedTab = selectedTab === tabNumber ? '' : tabNumber;
        setSelectedTab(newSelectedTab);
    };

    return (
        <div className="product-tab">
            <div className="product-tab-headers no-print" role="tablist">
                <WrapTrackingContext set={{ tab_name: 'produktside' }}>
                    <TabButton selected={selectedTab === '1'} onSelect={() => setSelectedTab('1')}>
                        Om produktet
                    </TabButton>
                    <TabButton selected={selectedTab === '2'} onSelect={() => setSelectedTab('2')}>
                        Min vurdering
                    </TabButton>
                    {product.similarProducts && (
                        <TabButton selected={selectedTab === '3'} onSelect={() => setSelectedTab('3')}>
                            Produkter som ligner
                        </TabButton>
                    )}
                </WrapTrackingContext>
            </div>

            <Expandable
                className="product-accordion product-accordion-header"
                title="Om produktet"
                onClickHeading={() => selectExpandable('1')}
                isExpanded={selectedTab === '1'}
            />
            <ProductTab title="Om produktet" selected={selectedTab === '1'}>
                <ProductTabDetails product={product} />
            </ProductTab>
            <Expandable
                className="product-accordion product-accordion-header"
                title="Min vurdering"
                onClickHeading={() => selectExpandable('2')}
                isExpanded={selectedTab === '2'}
            />
            <ProductTab selected={selectedTab === '2'} title="Min vurdering" className={cn({ 'no-print': !userData?.review })}>
                <RatingAndComment productId={product.code} review={userData?.review} onUserDataChange={onUserDataChange} />
            </ProductTab>
            {product.similarProducts && (
                <>
                    <Expandable
                        className="product-accordion product-accordion-header"
                        title="Produkter som ligner"
                        onClickHeading={() => selectExpandable('3')}
                        isExpanded={selectedTab === '3'}
                    />
                    <ProductTab selected={selectedTab === '3'} title="Produkter som ligner" className="no-print">
                        <SimilarProducts productId={product.code} />
                    </ProductTab>
                </>
            )}
        </div>
    );
}
